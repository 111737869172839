import {AxiosStatic} from 'axios'
import {actions} from '../../app/modules/auth'

export default function setupAxios(axios: AxiosStatic, store: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.defaults.headers.platform = 'desktop'
  axios.defaults.baseURL = process.env.REACT_APP_API_URL
  // axios.defaults.baseURL = 'http://localhost:8080'

  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: {accessToken},
      } = store.getState()
      if (config.url.indexOf('s3.') === -1 && accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (err) => {
      const statusCode = err?.response?.status
      const errMsg = err?.response?.data?.error || err.response.message
      switch (statusCode) {
        case 455:
          if (errMsg === 'Session expired. Please login again.') {
            store.dispatch(actions.logout())
          }
          break
      }
      return Promise.reject(err)
      // return err;
    }
  )
}
