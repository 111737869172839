import {get} from 'lodash'
import React, {useCallback, useEffect, useState} from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import SVG from 'react-inlinesvg'
import {useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import CustomLoadingAnimation from '../../components/CustomLoadingAnimation'
import {getQuestions} from './redux/Assessmentservice'

export const QuestionList = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [questionList, setQuestionList] = useState([])
  const [totalCount, setTotalCount] = useState(0)

  let initialFilters = {
    page: 1,
    limit: 10,
    sort: 'des',
    filter: {},
  }

  const [filters, setFilters] = useState(initialFilters)

  const onPageChange = async (page: any) => {
    setFilters({
      ...filters,
      page,
    })
  }

  const onSizePerPageChange = (limit: any) => {
    setFilters({
      ...filters,
      page: 1,
      limit,
    })
  }

  const getList = useCallback(async () => {
    try {
      setLoading(true)
      const result: any = await getQuestions(filters)
      const count = result.data.count
      const resp = get(result.data, 'data', [])
      setQuestionList(
        resp.map((org: any) => {
          return {
            _id: org._id,
            name: org.name,
            theme: [org.theme],
          }
        })
      )
      setTotalCount(count)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [filters])

  const onView = (id: any) => {
    navigate(`/assessment-questions/${id}`)
  }

  const onEdit = (_id: any) => {
    navigate(`/update-assessment/${_id}`)
  }

  const actionColumnView = (row: any) => {
    return (
      <>
        <div
          title='View Stats'
          className='btn btn-icon btn-light btn-hover-primary btn-sm mr-3'
          onClick={() => onView && onView(row)}
        >
          <span className='svg-icon svg-icon-md svg-icon-primary' title='View Stats'>
            <i className='fa fa-eye text-primary' aria-hidden='true'></i>
          </span>
        </div>
        <div
          title='Edit Admin'
          className='btn btn-icon btn-light btn-hover-primary btn-sm mx-3'
          onClick={() => onEdit && onEdit(row)}
        >
          <span className='svg-icon svg-icon-md svg-icon-primary' title='Edit Admin'>
            <SVG title='Edit Admin' src={toAbsoluteUrl('/media/icons/Write.svg')} />
          </span>
        </div>
      </>
    )
  }

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
    },
    {
      dataField: '_id',
      text: 'Actions',
      formatter: actionColumnView,

      headerStyle: {
        maxWidth: '50px',
      },
      style: {
        maxWidth: '50px',
      },
      align: 'right',
    },
  ]

  useEffect(() => {
    getList()
  }, [getList])
  return (
    <>
      <CustomLoadingAnimation isLoading={loading} />
      <div>
        <PageTitle breadcrumbs={[]}>Assessments</PageTitle>
        <div className='container-fluid mt-2'>
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-header p-3 justify-content-end'>
                  <button
                    className='btn btn-primary create-btn'
                    onClick={() => navigate(`/assessment-questions`)}
                  >
                    Create
                  </button>
                </div>
                <div className='col-12 p-5'>
                  {!loading && questionList.length > 0 && (
                    <BootstrapTable
                      wrapperClasses='table-responsive'
                      classes='table table-head-custom table-vertical-center overflow-hidden'
                      bootstrap4
                      remote
                      columns={columns}
                      data={questionList}
                      keyField='_id'
                      striped
                      pagination={paginationFactory({
                        page: filters.page,
                        sizePerPage: filters.limit,
                        totalSize: totalCount,
                        onPageChange: onPageChange,
                        onSizePerPageChange: onSizePerPageChange,
                      })}
                    />
                  )}
                </div>
                {/* If No records */}
                {!loading && questionList.length === 0 && (
                  <div className='row'>
                    <div className='col-xl-12'>
                      <div className='card'>
                        <div className='card-body text-center'>No results found</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
