import React, {useState, useEffect, useCallback} from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import {useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import CustomLoadingAnimation from '../../components/CustomLoadingAnimation'
import {getUserList} from './redux/UserWaitListService'
import SVG from 'react-inlinesvg'

export const UserWaitList = () => {
  const [loading, setLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0)
  const [userList, setUserList] = useState([])
  const navigate = useNavigate()

  let initialFilters = {
    page: 1,
    limit: 10,
    sort: 'des',
    filter: {},
  }
  const [filters, setFilters] = useState(initialFilters)

  const onPageChange = async (page: any) => {
    setFilters({
      ...filters,
      page,
    })
  }

  const onSizePerPageChange = (limit: any) => {
    setFilters({
      ...filters,
      page: 1,
      limit,
    })
  }

  const getList = useCallback(async () => {
    try {
      setLoading(true)
      const result = await getUserList(filters) //get data from api
      const count = result.data.count
      setUserList(result.data.data)
      setTotalCount(count)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [filters])

  const onEdit = (id: any) => {
    navigate(`/user-wait/edit/${id}`)
  }

  const actionColumnView = (rowStatus: any) => {
    return (
      <>
        <button
          title='Edit Admin'
          className='btn btn-icon btn-light btn-hover-success btn-sm mr-2 rounded'
          onClick={() => onEdit && onEdit(rowStatus)}
        >
          <i className={`fa fa-check ${rowStatus === 1 ? 'text-success' : 'text-danger'}`}></i>
        </button>
      </>
    )
  }

  const statusChange = (row: any) => {
    let status = ''
    switch (row) {
      case 0:
        status = 'PENDING'
        break
      case 1:
        status = 'APPROVED'
        break
      case 2:
        status = 'REJECTED'
        break
      default:
        status = 'PENDING'
    }
    return status
  }

  const columns = [
    {
      dataField: 'email',
      text: 'User Email',
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: statusChange,
    },
    {
      dataField: 'role',
      text: 'Role',
    },
    {
      // dataField: '_id',
      dataField: 'status',
      text: 'Actions',
      formatter: actionColumnView,
      headerStyle: {
        maxWidth: '50px',
      },
      style: {
        maxWidth: '50px',
      },
      align: 'right',
    },
  ]

  useEffect(() => {
    getList()
  }, [filters, getList])

  return (
    <>
      <div>
        <PageTitle>Wait List</PageTitle>
        <div className='container-fluid mt-2'>
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <CustomLoadingAnimation isLoading={loading} />
                <div className='col-12 p-5'>
                  {!loading && userList.length > 0 && (
                    <BootstrapTable
                      wrapperClasses='table-responsive'
                      classes='table table-head-custom table-vertical-center overflow-hidden'
                      bootstrap4
                      remote
                      columns={columns}
                      data={userList}
                      keyField='_id'
                      striped
                      pagination={paginationFactory({
                        page: filters.page,
                        sizePerPage: filters.limit,
                        totalSize: totalCount,
                        onPageChange: onPageChange,
                        onSizePerPageChange: onSizePerPageChange,
                      })}
                    />
                  )}
                </div>
                {/* If No records */}
                {!loading && userList.length === 0 && (
                  <div className='row'>
                    <div className='col-xl-12'>
                      <div className='card'>
                        <div className='card-body text-center'>No results found</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
