import {useCallback, useEffect, useState} from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import SVG from 'react-inlinesvg'
import {useNavigate} from 'react-router'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import CustomLoadingAnimation from '../../components/CustomLoadingAnimation'
import {getThemeList} from './redux/ThemesService'

export const ThemeList = () => {
  const [themeList, setThemeList] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  let initialFilters = {
    page: 1,
    limit: 10,
    sort: 'des',
    filter: {},
  }
  const [filters, setFilters] = useState(initialFilters)

  const getList = useCallback(async () => {
    try {
      setLoading(true)
      const result = await getThemeList(filters)
      const count = result.data.count
      setThemeList(result.data.data)
      setTotalCount(count)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [filters])

  const onPageChange = async (page: any) => {
    setFilters({
      ...filters,
      page,
    })
  }

  const onSizePerPageChange = (limit: any) => {
    setFilters({
      ...filters,
      page: 1,
      limit,
    })
  }

  const onEdit = (id: any) => {
    navigate(`/theme/edit/${id}`)
  }

  const actionColumnView = (row: any) => {
    return (
      <>
        <div
          title='Edit Admin'
          className='btn btn-icon btn-light btn-hover-primary btn-sm mx-3'
          onClick={() => onEdit && onEdit(row)}
        >
          <span className='svg-icon svg-icon-md svg-icon-primary' title='Edit Admin'>
            <SVG title='Edit Admin' src={toAbsoluteUrl('/media/icons/Write.svg')} />
          </span>
        </div>
      </>
    )
  }

  const columns = [
    {
      dataField: 'name',
      text: 'Theme Name',
    },
    {
      dataField: 'description',
      text: 'Description',
    },
    {
      dataField: 'enable',
      text: 'Enable',
    },
    {
      dataField: '_id',
      text: 'Actions',
      formatter: actionColumnView,

      headerStyle: {
        maxWidth: '50px',
      },
      style: {
        maxWidth: '50px',
      },
      align: 'right',
    },
  ]

  useEffect(() => {
    getList()
  }, [getList])

  return (
    <>
      <CustomLoadingAnimation isLoading={loading} />
      <div>
        <PageTitle breadcrumbs={[]}>Themes</PageTitle>
        <div className='container-fluid mt-2'>
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-header p-3 justify-content-end'>
                  <button
                    className='btn btn-primary'
                    style={{width: '100px'}}
                    onClick={() => navigate(`/theme/create`)}
                  >
                    Create
                  </button>
                </div>
                <div className='col-12 p-5'>
                  {!loading && themeList.length > 0 && (
                    <BootstrapTable
                      wrapperClasses='table-responsive'
                      classes='table table-head-custom table-vertical-center overflow-hidden'
                      bootstrap4
                      remote
                      columns={columns}
                      data={themeList}
                      keyField='_id'
                      striped
                      pagination={paginationFactory({
                        page: filters.page,
                        sizePerPage: filters.limit,
                        totalSize: totalCount,
                        onPageChange: onPageChange,
                        onSizePerPageChange: onSizePerPageChange,
                      })}
                    />
                  )}
                </div>
                {/* If No records */}
                {!loading && themeList.length === 0 && (
                  <div className='row'>
                    <div className='col-xl-12'>
                      <div className='card'>
                        <div className='card-body text-center'>No results found</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
