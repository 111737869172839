import axios from 'axios'

const GET_USER_LIST = `/user/allSignedUpUsers`
const UPDATE_USER = `/user/updateSignUpUsers`
const GET_USER_BY_ID = `/user`
const RESET = `/user/reset`
const GET_ORGANIZATION_LIST = `/organization/filterOrganization`

export const getUSerList = (req: any) => {
  return axios.post(GET_USER_LIST, req)
}
export function EditUser(req: any) {
  return axios.patch(UPDATE_USER, req)
}

export function getUserById(id: any) {
  return axios.get(`${GET_USER_BY_ID}/${id}`)
}

export const getOrganizationList = () => {
  return axios.post(GET_ORGANIZATION_LIST)
}

export const deleteUserAPI = (id: string) => {
  return axios.get(`${RESET}/${id}`)
}
