import clsx from 'clsx'
import {Formik} from 'formik'
import {useSnackbar} from 'notistack'
import {useCallback, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router'
import {PageTitle} from '../../../_metronic/layout/core'
import {CreateTheme, getThemeById, UpdateTheme} from './redux/ThemesService'

export const ThemeEdit = () => {
  const params = useParams()
  const {enqueueSnackbar} = useSnackbar()
  const navigate = useNavigate()
  const defaultThemeDetails = {
    name: '',
    description: '',
    enable: '',
    thumbnail: '',
  }

  const [themeData, setThemeData] = useState(defaultThemeDetails)
  const getDetails = useCallback(async () => {
    try {
      const result = await getThemeById(params.id)
      setThemeData(result.data)
    } catch (error) {
      console.log(error)
    }
  }, [params?.id])

  const onSubmit = async (values: any) => {
    if (params.id) {
      const req = {_id: params.id, name: values.name, description: values.description}
      await UpdateTheme(req)
      enqueueSnackbar('Updated Successfully', {variant: 'success'})
      navigate(`/themes`)
    } else {
      const req = {
        name: values.name,
        description: values.description,
        enable: 'true',
        thumbnail: 'fdf',
      }
      await CreateTheme(req)
      enqueueSnackbar('Created Successfully', {variant: 'success'})
      navigate(`/themes`)
    }
  }

  const onValidate = async (values: any) => {
    let error: any = {}
    if (!values.name) {
      error.name = 'Name is required'
    }
    if (!values.description) {
      error.description = 'Description is required'
    }
    return error
  }

  useEffect(() => {
    getDetails()
  }, [getDetails])

  return (
    <>
      <div>
        <PageTitle breadcrumbs={[]}>{params.id ? 'Edit-Theme' : 'Create-Theme'}</PageTitle>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-header justify-content-start align-items-center p-0'>
                  <button type='button' className='btn p-3' onClick={() => navigate(`/themes`)}>
                    <img
                      alt=''
                      className='arrow-btn'
                      src='/media/icons/duotune/arrows/arr063.svg'
                    />
                  </button>
                  <p className='fs-4 fw-bolder text-dark m-0'>Back</p>
                </div>
                <Formik
                  enableReinitialize
                  initialValues={themeData}
                  onSubmit={onSubmit}
                  validate={onValidate}
                >
                  {({values, errors, touched, handleSubmit, handleChange}) => (
                    <div>
                      <div className='row p-5'>
                        <div className='col-6'>
                          <label className='form-label fs-6 fw-bolder text-dark'>Name</label>
                          <input
                            placeholder='Name'
                            value={values.name}
                            className={clsx('form-control form-control-lg form-control-solid', {
                              'is-invalid': errors.name,
                            })}
                            onChange={handleChange('name')}
                            type='text'
                            name='name'
                            autoComplete='off'
                          />
                          <div className='text-danger d-block'>{errors.name || ''}</div>
                        </div>
                        <div className='col-6'>
                          <label className='form-label fs-6 fw-bolder text-dark'>Description</label>
                          <input
                            placeholder='Description'
                            value={values.description}
                            className={clsx('form-control form-control-lg form-control-solid', {
                              'is-invalid': errors.description,
                            })}
                            onChange={handleChange('description')}
                            type='text'
                            name='description'
                            autoComplete='off'
                          />
                          <div className='text-danger d-block'>{errors.description || ''}</div>
                        </div>
                      </div>
                      <div className='text-end p-5'>
                        <button
                          key={`Admin-Submit-button`}
                          type='button'
                          className='btn btn-primary'
                          onClick={() => handleSubmit()}
                        >
                          {params.id ? 'Update' : 'Create'}
                        </button>
                      </div>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
