import clsx from 'clsx'
import {Formik} from 'formik'
import {useSnackbar} from 'notistack'
import React, {useCallback, useEffect, useState} from 'react'
import {useParams} from 'react-router'
import {useNavigate} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import '../../index.scss'
import {
  CreateOrganization,
  EditOrganization,
  getOrganizationById,
} from './redux/organizationService'

export const OrganizationEdit = () => {
  const params = useParams()
  const {enqueueSnackbar} = useSnackbar()
  const navigate = useNavigate()
  const [editMode, setEditMode] = useState(false)

  const defaultDetails = {
    organizationName: '',
  }
  const [organizationData, setOrganizationData] = useState(defaultDetails)

  const getOrganizationDetails = useCallback(async () => {
    try {
      const result = await getOrganizationById(params.id)
      setOrganizationData(result.data)
    } catch (error) {
      console.log(error)
    }
  }, [params.id])

  useEffect(() => {
    if (params.id) {
      setEditMode(true)
      getOrganizationDetails()
    }
  }, [params.id, getOrganizationDetails])

  const onValidate = async (values: any) => {
    let error: any = {}
    if (!values.organizationName) {
      error.organizationName = 'Required'
    }
    return error
  }

  const onSubmit = async (values: any) => {
    if (editMode) {
      const req = {_id: params.id, organizationData: {organizationName: values.organizationName}}
      await EditOrganization(req)
      enqueueSnackbar('Updated Successfully', {variant: 'success'})
      navigate(`/organization`)
    } else {
      await CreateOrganization(values.organizationName)
      enqueueSnackbar('Created Successfully', {variant: 'success'})
      navigate(`/organization`)
    }
  }

  return (
    <>
      <div>
        <PageTitle breadcrumbs={[]}>
          {params.id ? 'Edit Organization' : 'Create Organization'}
        </PageTitle>
        <div className='mt-2'>
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-header justify-content-start align-items-center p-0'>
                  <button
                    type='button'
                    className='btn p-3'
                    onClick={() => navigate(`/organization`)}
                  >
                    <img
                      className='arrow-btn'
                      alt=''
                      src='/media/icons/duotune/arrows/arr063.svg'
                    />
                  </button>
                  <p className='fs-4 fw-bolder text-dark m-0'>Back</p>
                </div>
                {organizationData && (
                  <Formik
                    enableReinitialize
                    initialValues={organizationData}
                    onSubmit={onSubmit}
                    validate={onValidate}
                  >
                    {({values, touched, errors, handleSubmit, handleChange}) => (
                      <div>
                        <div className='row p-5'>
                          <div className='col-6'>
                            <label className='form-label fs-6 fw-bolder text-dark'>
                              Organization Name
                            </label>
                            <input
                              placeholder='Organization Name'
                              value={values.organizationName}
                              className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid': errors.organizationName,
                              })}
                              onChange={handleChange('organizationName')}
                              type='text'
                              name='organizationName'
                              autoComplete='off'
                              required
                            />
                            <div className='text-danger d-block'>
                              {errors.organizationName || ''}
                            </div>
                          </div>
                        </div>
                        <div className='text-end p-5'>
                          <button
                            key={`Admin-Submit-button`}
                            type='button'
                            className='btn btn-primary'
                            onClick={() => handleSubmit()}
                          >
                            {params.id ? 'Update' : 'Create'}
                          </button>
                        </div>
                      </div>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
