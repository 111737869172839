import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {QuestionList} from '../modules/AssessmentQuestions/AssessmentList'
import {QuestionsPage} from '../modules/AssessmentQuestions/AssessmentQuestions'
import {UploadPage} from '../modules/AssessmentQuestions/UpdateAssessments'
import {BiohackEdit} from '../modules/Biohacks/BiohackEdit'
import {BiohackList} from '../modules/Biohacks/BiohackList'
import {OrganizationEdit} from '../modules/Organization/OrganizationEdit'
import {OrganizationPage} from '../modules/Organization/OrganizationPage'
import {ThemeEdit} from '../modules/Themes/ThemeEdit'
import {ThemeList} from '../modules/Themes/ThemeList'
import {UserEdit} from '../modules/User/pages/UserEdit'
import UserList from '../modules/User/pages/UserList'
import {UserWaitEdit} from '../modules/UserWaitList/UserWaitEdit'
import {UserWaitList} from '../modules/UserWaitList/UserWaitList'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/organization' />} />
        {/* Pages */}
        {/* organizations */}
        <Route path='organization' element={<OrganizationPage />} />
        <Route path='organization/edit/:id' element={<OrganizationEdit />} />
        <Route path='organization/create' element={<OrganizationEdit />} />
        {/* organizations */}
        {/* users */}
        <Route path='users' element={<UserList />} />
        <Route path='user/edit/:id' element={<UserEdit />} />
        {/* users */}
        {/* themes */}
        <Route path='theme/edit/:id' element={<ThemeEdit />} />
        <Route path='theme/create' element={<ThemeEdit />} />
        <Route path='themes' element={<ThemeList />} />
        {/* themes */}
        {/* Biohacks */}
        <Route path='biohack' element={<BiohackList />} />
        <Route path='biohack/edit/:id' element={<BiohackEdit />} />
        <Route path='biohack/create' element={<BiohackEdit />} />
        {/* Biohacks */}
        {/* Assessments */}
        <Route path='update-assessment/:id' element={<UploadPage />} />
        <Route path='assessment-questions' element={<UploadPage />} />
        <Route path='assessment' element={<QuestionList />} />
        <Route path='theme/assessment-questions/:id' element={<QuestionsPage />} />
        {/* Assessments */}
        {/* User wait List */}
        <Route path='user-wait/list' element={<UserWaitList />} />
        <Route path='user-wait/edit/:id' element={<UserWaitEdit />} />
        {/* User wait List */}
        <Route path='assessment-questions/:id' element={<QuestionsPage />} />

        {/* Lazy Modules */}

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

// const SuspensedView: FC = ({children}) => {
//   const baseColor = getCSSVariableValue('--bs-primary')
//   TopBarProgress.config({
//     barColors: {
//       '0': baseColor,
//     },
//     barThickness: 1,
//     shadowBlur: 5,
//   })
//   return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
// }

export {PrivateRoutes}
