/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useCallback, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useParams} from 'react-router'
import {useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import CustomLoadingAnimation from '../../../components/CustomLoadingAnimation'
import {verifyUser, login} from '../redux/AuthCRUD'
import * as auth from '../redux/AuthRedux'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  // name: Yup.string()
  //   .min(3, 'Minimum 3 symbols')
  //   .max(50, 'Maximum 50 symbols')
  //   .required('Password is required'),
})

const initialValues = {
  email: '',
  code: '',
}

export function Login() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {code, email} = useParams()
  const [editMode, setEditMode] = useState(false)
  const [apiError, setApiError] = useState('')

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState('')

  const verifyData = useCallback(async () => {
    try {
      setLoading(true)
      setApiError('')
      const response = await verifyUser(email!, code!)
      const userDetails = response.data.token
      dispatch(auth.actions.login(response.data.token))
      dispatch(
        auth.actions.setUser({
          email: userDetails.email,
          first_name: userDetails.name,
          id: userDetails.sub,
          last_name: '',
          password: '',
          username: userDetails.email,
          auth: {
            token: response.data.token,
            refreshToken: response.data.refreshToken,
          },
        })
      )
      setLoading(false)
      setEditMode(true)
      navigate(`/organization`)
    } catch (error: any) {
      if (error && error.message) {
        setApiError(error.message)
      }
      setEditMode(false)
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [code, email, dispatch, navigate])

  const loginUser = async (email: string) => {
    try {
      setLoading(true)
      const result = await login(email)

      setData(result.data.message)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,

    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      loginUser(values.email).catch(() => {
        setLoading(false)
        setSubmitting(false)
        setStatus('The login detail is incorrect')
      })
    },
  })

  useEffect(() => {
    if (email && code) verifyData()
  }, [email, code, verifyData])

  return (
    <div>
      <CustomLoadingAnimation isLoading={loading} />

      {!loading && editMode === false && (
        <form
          className='form w-100'
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >
          {/* begin::Heading */}
          <div className='text-center mb-10'>
            <h1 className='text-dark mb-3'>Sign In </h1>
          </div>

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
            <input
              placeholder='Email'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              type='email'
              name='email'
              autoComplete='off'
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          {/* <div className='fv-row mb-10'>
            <div className='d-flex justify-content-between mt-n5'>
              <div className='d-flex flex-stack mb-2'>
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>Name</label>
              </div>
            </div>
            <input
              placeholder='Name'
              type='name'
              autoComplete='off'
              {...formik.getFieldProps('name')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.name && formik.errors.name},
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
          </div> */}
          {/* end::Form group */}

          {/* begin::Action */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Continue</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>

            <p style={{color: 'blue'}}>{data}</p>
            {apiError ? (
              <div className='text-center text-danger'>
                Oops,Something's went wrong.<p>Please Try Again</p>
              </div>
            ) : null}
          </div>
          {/* end::Action */}
        </form>
      )}
    </div>
  )
}
