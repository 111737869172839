import axios from 'axios'
const GET_BIOHACK_BY_ID = `/biohack`

export function getBiohacks(req: any) {
  return axios.post('/biohack/getBiohack', req)
}

export function createBiohack(req: any) {
  return axios.post('/biohack/createBiohack', req)
}

export function updateBiohack(id: string, req: any) {
  return axios.patch(`/biohack/${id}`, req)
}

export function getBiohackById(id: any) {
  return axios.get(`${GET_BIOHACK_BY_ID}/${id}`)
}
