import React, {useCallback, useEffect, useState} from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import {useParams} from 'react-router'
import {useNavigate} from 'react-router-dom'
import '../../../app/index.scss'
import {PageTitle} from '../../../_metronic/layout/core'
import CustomLoadingAnimation from '../../components/CustomLoadingAnimation'
import {QUESTION_TYPES} from '../../utils/constants'
import {
  getPreSignedUrl,
  getQuestionsById,
  updateQuestion,
  uploadToS3,
} from './redux/Assessmentservice'

export const QuestionsPage = () => {
  const params = useParams()
  const navigate = useNavigate()
  const [questionsList, setQuestionsList] = useState([])
  const [loading, setLoading] = useState(false)

  const getQuestionsList = useCallback(async () => {
    try {
      setLoading(true)
      const result = await getQuestionsById(params.id)
      setQuestionsList(result.data.questions)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [params?.id])

  const columns = [
    {
      dataField: 'questionNo',
      text: 'No',
    },
    {
      dataField: 'question',
      text: 'Question',
    },
    {
      dataField: 'stage',
      text: 'Stage',
    },
    {
      dataField: 'type',
      text: 'Type',
    },
    {
      dataField: 'buttonText',
      text: 'Button Text',
    },
  ]

  const optionImageSelect = async (e: any, row: any, optionId: number) => {
    setLoading(true)
    const file = e.target.files[0]
    const fileExtenstion = file.name.split('.').pop()
    const filePath = `images/${params.id}-${row.questionNo}-${optionId}.${fileExtenstion}`
    const resp = await getPreSignedUrl(filePath)

    try {
      const response = await uploadToS3(
        resp.data.url,
        file,
        new Headers({'Content-Type': 'image/*'})
      )
      if (response.ok) {
        await updateQuestion({
          questionsId: params.id,
          questionNo: row.questionNo,
          optionId: optionId,
          imageUrl: filePath,
        })
      }
      getQuestionsList()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const expandRow = {
    renderer: (row: any, rowIndex: number) => (
      <div key={`${params.id}-${row.questionNo}`}>
        {(row.type === QUESTION_TYPES.SINGLE_SELECT_WITH_ICON ||
          row.type === QUESTION_TYPES.SINGLE_SELECT_WITH_BACKGROUND) &&
          row?.options?.length > 0 &&
          row?.questionNo === rowIndex + 1 &&
          row.options.map((item: any) => (
            <div className='row mb-3' key={`${params.id}-${row.questionNo}-${item.id}`}>
              <div className='col-2 pt-backtxt page-title-p'>{item.option || item}</div>
              <div className='col-10'>
                <div className='d-flex align-items-center'>
                  <div className='page-title-p'>
                    <label>{item.imageUrl ? 'Replace' : 'Upload'} Image</label>
                    <input
                      className='form-control'
                      type='file'
                      id='formFile'
                      name='file_upload'
                      onChange={(e) => optionImageSelect(e, row, item.id)}
                      accept='image/*'
                    />
                  </div>
                  {item.imageUrl && (
                    <div className='ms-5'>
                      <img
                        src={item.imageUrl}
                        alt={`${params.id}-${row.questionNo}-${item.id}`}
                        height='50'
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    ),
    showExpandColumn: true,
    onlyOneExpanding: true,
    nonExpandable: [1, 2, 3, 4, 5, 6, 7, 12],
  }

  useEffect(() => {
    if (params?.id) {
      getQuestionsList()
    }
  }, [params?.id, getQuestionsList])

  return (
    <>
      <CustomLoadingAnimation isLoading={loading} />
      <div>
        <PageTitle breadcrumbs={[]}>Assessment-Questions</PageTitle>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-header justify-content-start align-items-center p-0'>
                  <button type='button' className='btn p-3' onClick={() => navigate(`/assessment`)}>
                    <img
                      className='arrow-btn'
                      alt=''
                      src='/media/icons/duotune/arrows/arr063.svg'
                    />
                  </button>
                  <p className='fs-4 fw-bolder text-dark m-0'>Back</p>
                </div>
                <div className='col-12 p-5'>
                  {questionsList?.length > 0 && (
                    <BootstrapTable
                      classes='table table-head-custom table-vertical-center overflow-hidden'
                      bordered
                      striped
                      remote
                      keyField='questionNo'
                      columns={columns}
                      data={questionsList}
                      expandRow={expandRow}
                    />
                  )}
                </div>
                {/* If No records */}
                {questionsList?.length === 0 && (
                  <div className='row'>
                    <div className='col-xl-12'>
                      <div className='card'>
                        <div className='card-body text-center'>No results found</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
