import axios from 'axios'

const GET_THEMES_LIST = `/theme/Theme`
const GET_THEME_BY_ID = `/theme`
const CREATE_THEME = `/theme/create`
const UPDATE_THEME = `/theme/updateTheme`
const GET_QUESTIONS = `/assessment-questions/GetById`

export const getThemeList = (req: any) => {
  return axios.post(GET_THEMES_LIST, req)
}

export function getThemeById(id: any) {
  return axios.get(`${GET_THEME_BY_ID}/${id}`)
}

export function CreateTheme(req: any) {
  return axios.post(CREATE_THEME, req)
}

export function UpdateTheme(req: any) {
  return axios.patch(UPDATE_THEME, req)
}

export function getQuestionsById(req: any) {
  return axios.post(GET_QUESTIONS, req)
}
