import clsx from 'clsx'
import {Formik} from 'formik'
import {get} from 'lodash'
import {useSnackbar} from 'notistack'
import React, {useCallback, useEffect, useState} from 'react'
import {useParams} from 'react-router'
import {useNavigate} from 'react-router-dom'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import {PageTitle} from '../../../_metronic/layout/core'
import CustomLoadingAnimation from '../../components/CustomLoadingAnimation'
import {
  getFile,
  getPreSignedUrl,
  getQuestionsById,
  getThemes,
  updateFile,
  uploadToS3,
} from './redux/Assessmentservice'

export const UploadPage = () => {
  const params = useParams()
  const navigate = useNavigate()
  const {enqueueSnackbar} = useSnackbar()
  const [imgData, setImgData] = useState('')

  const defaultDetails = {
    name: '',
    theme: [],
    time: '',
    tag: [],
    imageUrl: imgData,
  }
  const detail = {
    imageUrl: '',
  }
  const [loading, setLoading] = useState<boolean>(false)
  const [themeList, setThemeList] = useState<any[]>([])
  const [questionData, setQuestionData] = useState(defaultDetails)

  const getThemeList = useCallback(async () => {
    try {
      const result = await getThemes()
      const data = get(result.data, 'data', [])
      setThemeList(
        data.map((res: any) => {
          return {
            label: res.name,
            value: res._id,
          }
        })
      )
    } catch (error) {
      console.log(error)
    }
  }, [])

  const getDetails = useCallback(async () => {
    if (params.id) {
      try {
        const result: any = await getQuestionsById(params.id)
        setQuestionData(result.data)
      } catch (error) {
        console.log(error)
      }
    }
  }, [params.id])

  let formData = new FormData()

  const onSubmit = async (values: any) => {
    console.log('values', values)
    formData = new FormData()
    formData.append('theme', JSON.stringify(values.theme))
    formData.append('name', values.name)
    if (values?.tag?.length) {
      formData.append('tag', JSON.stringify(values.tag))
    }
    if (params.id) {
      formData.append('_id', JSON.stringify(params.id))
      await updateFile(params.id, formData)
    } else {
      await getFile(formData)
      console.log('formData', formData)
    }

    enqueueSnackbar('Uploaded Successfully', {variant: 'success'})
    navigate(`/assessment`)
  }

  const onFileChange = async (e: any) => {
    if (e.target && e.target.files[0]) {
      setLoading(true)
      const file = e.target.files[0]
      const fileExtenstion = file.name.split('.').pop()
      const filePath = params.id
        ? `images/${params.id}.${fileExtenstion}`
        : `images/${new Date().getTime()}-${file.name}`

      formData.append('imageUrl', filePath)

      const resp = await getPreSignedUrl(filePath)

      try {
        await uploadToS3(resp.data.url, file, new Headers({'Content-Type': 'image/*'}))
        setQuestionData({...questionData, imageUrl: filePath})
        // setImgData(filePath)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
  }

  const getImageUrl = (url: string) => {
    if (url.startsWith('http')) {
      return url
    }
    console.log('url', url)
    return `${process.env.REACT_APP_S3_BASE_URL}/${url}`
  }
  const onValidate = async (values: any) => {
    let error: any = {}
    if (!values.name) {
      error.name = 'name is Required'
    }
    if (!values.instructions) {
      error.instructions = 'Instructions are required'
    }
    if (!values.detailScience) {
      error.detailScience = 'Description is required'
    }
    if (!values.executionInterval) {
      error.executionInterval = 'Interval is required'
    }
    if (!values.time) {
      error.time = 'Time is required'
    }
    return error
  }

  useEffect(() => {
    getThemeList()
    getDetails()
  }, [getThemeList, getDetails])

  return (
    <>
      {/* <CustomLoadingAnimation isLoading={loading} /> */}
      <div>
        <PageTitle breadcrumbs={[]}>
          {params.id ? 'Edit Assessments' : 'Create Assessments'}
        </PageTitle>
        <div className='container-fluid mt-2'>
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-header justify-content-start align-items-center p-0'>
                  <button type='button' className='btn p-3' onClick={() => navigate(`/assessment`)}>
                    <img
                      alt=''
                      className='arrow-btn'
                      src='/media/icons/duotune/arrows/arr063.svg'
                    />
                  </button>
                  <p className='fs-4 fw-bolder text-dark m-0'>Back</p>
                </div>
                <Formik
                  enableReinitialize
                  initialValues={questionData}
                  onSubmit={(v) => onSubmit(v)}
                  validate={onValidate}
                >
                  {({values, handleSubmit, handleChange, setFieldValue, touched, errors}) => (
                    <div>
                      <div className='row p-3'>
                        <div className='col-6'>
                          <label className='form-label fs-6 fw-bolder text-dark'>Name</label>
                          <input
                            placeholder='Name'
                            value={values.name}
                            onChange={handleChange('name')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                              'is-invalid': errors.name,
                            })}
                            type='text'
                            name='name'
                            autoComplete='off'
                          />
                          <div className='text-danger d-block'>{errors.name || ''}</div>
                        </div>
                        <div className='col-6'>
                          <label className='form-label fs-6 fw-bolder text-dark'>Tags</label>
                          <CreatableSelect
                            isClearable
                            isMulti
                            onChange={(e: any) => {
                              setFieldValue(
                                'tag',
                                e.map((x: any) => x.value)
                              )
                            }}
                            value={values.tag.map((t) => {
                              if (typeof t === 'string') {
                                return {
                                  label: t,
                                  value: t,
                                }
                              }
                              return t
                            })}
                          />
                        </div>
                      </div>
                      <div className='row p-3'>
                        <div className='col-6'>
                          <label className='form-label fs-6 fw-bolder text-dark'>Time</label>
                          <input
                            placeholder='add Time'
                            value={values.time}
                            onChange={handleChange('time')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                              'is-invalid': errors.time,
                            })}
                            type='number'
                            name='time'
                            autoComplete='off'
                          />
                          <div className='text-danger d-block'>{errors.time || ''}</div>
                        </div>
                        <div className='col-6'>
                          <label className='form-label fs-6 fw-bolder text-dark'>Themes</label>
                          <Select
                            value={values.theme.map((t) => themeList.find((tl) => tl.value === t))}
                            options={themeList}
                            onChange={(e) => {
                              setFieldValue(
                                'theme',
                                e.map((x) => x.value)
                              )
                            }}
                            isMulti={true}
                          />
                        </div>
                      </div>
                      <div className='row p-5'>
                        <div className='col-6'>
                          <label className='form-label fs-6 fw-bolder text-dark'>Select File</label>
                          <input
                            className='form-control'
                            type='file'
                            id='formFile'
                            name='file_upload'
                            onChange={onFileChange}
                          />
                        </div>
                      </div>
                      <div className='row p-5'>
                        <div className='col-6'>
                          <label className='form-label fs-6 fw-bolder text-dark'>
                            {values.imageUrl ? 'Replace' : 'Select'} Image
                          </label>
                          <input
                            className='form-control'
                            type='file'
                            id='formFile'
                            name='file_upload'
                            onChange={onFileChange}
                          />
                        </div>
                        {values.imageUrl && (
                          <div className='col-6'>
                            <img src={getImageUrl(values.imageUrl)} alt='img' height={100} />
                          </div>
                        )}
                      </div>
                      <div className='text-end p-5'>
                        <button
                          key={`Admin-Submit-button`}
                          type='button'
                          className='btn btn-primary'
                          onClick={() => handleSubmit()}
                        >
                          Upload
                        </button>
                      </div>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
