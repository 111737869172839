/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Login} from '../auth/components/Login'

export const LoginPage = () => {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-root'>
      <div
        className='login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white'
        id='kt_login'
      >
        {/*begin::Aside*/}

        <div
          className='login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10'
          style={{
            backgroundImage: `url(${toAbsoluteUrl('/media/misc/search-bg.png')})`,
          }}
        >
          {/*begin: Aside Container*/}
          <div className='d-flex flex-row-fluid flex-column justify-content-between'>
            {/* start:: Aside content */}
            <div
              className='flex-column-fluid d-flex flex-column justify-content-center'
              style={{width: '500px'}}
            >
              <h3 className='font-size-h1 mb-5 text-center'>
                <a className='navbar-brand' href='#'>
                  <img
                    src={toAbsoluteUrl('/media/logos/Group.png')}
                    width='200px'
                    className='d-inline-block align-top'
                    alt='Myto Logo'
                  />
                </a>
              </h3>
            </div>
            {/* end:: Aside content */}
          </div>
          {/*end: Aside Container*/}
        </div>
        {/*begin::Aside*/}

        {/*begin::Content*/}
        <div className='flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden'>
          {/* begin::Content body */}
          <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
            <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
              <Login />
            </div>
          </div>
          {/*end::Content body*/}
        </div>
        {/*end::Content*/}
      </div>
    </div>
  )
}
