import axios from 'axios'

export const VERIFY_LINK_URL = `/auth/verifyLink`
export const LOGIN_URL = `/auth/signUp`
export const REGISTER_URL = `/register`
export const REQUEST_PASSWORD_URL = `/forgot_password`

// Server should return AuthModel
export function login(email: string) {
  return axios.post(LOGIN_URL, {
    email,
    domain: window.location.origin,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

export function verifyUser(email: string, code: string) {
  return axios.post(VERIFY_LINK_URL, {
    email: email,
    code: code,
  })
}
