import {TextField} from '@mui/material'
import clsx from 'clsx'
import {FieldArray, Formik} from 'formik'
import {useSnackbar} from 'notistack'
import React, {useCallback, useEffect, useState} from 'react'
import {useParams} from 'react-router'
import {useNavigate} from 'react-router-dom'
import CreatableSelect from 'react-select/creatable'
import {PageTitle} from '../../../_metronic/layout/core'
import '../../index.scss'
import {getPreSignedUrl, uploadToS3} from '../AssessmentQuestions/redux/Assessmentservice'
import {createBiohack, getBiohackById, updateBiohack} from './redux/BiohackService'

export const BiohackEdit = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [imageData, setImageData] = useState('')
  const params = useParams()
  const navigate = useNavigate()
  const {enqueueSnackbar} = useSnackbar()
  const defaultDetails = {
    title: '',
    instructions: [''],
    detailScience: '',
    tags: [],
    executionInterval: '',
    executionTime: '',
    imageUrl: imageData,
  }

  const [biohackData, setBiohackData] = useState(defaultDetails)

  const getDetails = useCallback(async () => {
    try {
      const result: any = await getBiohackById(params.id)
      result.data.tags = result.data.tags.map((t: any) => ({value: t, label: t}))
      setBiohackData({...result.data})
    } catch (error) {
      console.log(error)
    }
  }, [])

  let formData = new FormData()

  const onFileChange = async (e: any) => {
    if (e.target && e.target.files[0]) {
      const file = e.target.files[0]
      const fileExtenstion = file.name.split('.').pop()
      const filePath = params.id
        ? `images/${params.id}.${fileExtenstion}`
        : `images/${new Date().getTime()}-${file.name}`
      formData.append('imageUrl', filePath)
      const resp = await getPreSignedUrl(filePath)

      try {
        await uploadToS3(resp.data.url, file, new Headers({'Content-Type': 'image/*'}))
        setImageData(filePath)
      } catch (error) {
        console.log(error)
      } finally {
      }
    }
  }

  const onSubmit = async (values: any) => {
    if (params.id) {
      const req = {
        title: values.title,
        instructions: values.instructions,
        detailScience: values.detailScience,
        tags: values.tags.map((t: any) => t.value),
        executionInterval: values.executionInterval,
        executionTime: values.executionTime,
        imageUrl: imageData,
      }

      await updateBiohack(params.id, req)
      enqueueSnackbar('Updated Successfully', {variant: 'success'})
      navigate(`/biohack`)
    } else {
      const req = {
        title: values.title,
        instructions: values.instructions,
        detailScience: values.detailScience,
        tags: values.tags.map((t: any) => t.value),
        executionInterval: values.executionInterval,
        executionTime: values.executionTime,
        imageUrl: imageData,
      }
      await createBiohack(req)
      enqueueSnackbar('Created Successfully', {variant: 'success'})
      navigate(`/biohack`)
    }
  }

  const getImageUrl = (url: string) => {
    if (url.startsWith('http')) {
      return url
    }
    return `${process.env.REACT_APP_S3_BASE_URL}/${url}`
  }

  const onValidate = async (values: any) => {
    let error: any = {}
    if (!values.title) {
      error.title = 'Title is Required'
    }
    if (!values.instructions) {
      error.instructions = 'Instructions are required'
    }
    if (!values.detailScience) {
      error.detailScience = 'Description is required'
    }
    if (!values.executionInterval) {
      error.executionInterval = 'Interval is required'
    }
    if (!values.executionTime) {
      error.executionTime = 'Time is required'
    }
    return error
  }

  useEffect(() => {
    getDetails()
  }, [])

  return (
    <>
      <div>
        <PageTitle breadcrumbs={[]}>{params.id ? 'Edit Biohack' : 'Create Biohack'}</PageTitle>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-header justify-content-start align-items-center p-0'>
                  <button type='button' className='btn p-3' onClick={() => navigate(`/biohack`)}>
                    <img
                      alt=''
                      className='arrow-btn'
                      src='/media/icons/duotune/arrows/arr063.svg'
                    />
                  </button>
                  <p className='fs-4 fw-bolder text-dark m-0'>Back</p>
                </div>
                <Formik
                  enableReinitialize
                  initialValues={biohackData}
                  onSubmit={(v) => onSubmit(v)}
                  validate={onValidate}
                >
                  {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => (
                    <div>
                      <div className='row p-5'>
                        <div className='col-6'>
                          <label className='form-label fs-6 fw-bolder text-dark'>Title</label>
                          <input
                            placeholder='Title'
                            value={values.title}
                            className={clsx('form-control form-control-lg form-control-solid', {
                              'is-invalid': errors.title,
                            })}
                            onChange={handleChange('title')}
                            type='text'
                            name='title'
                            autoComplete='off'
                          />
                          <div className='text-danger d-block'>{errors.title || ''}</div>
                        </div>
                        <div className='col-6'>
                          <label className='form-label fs-6 fw-bolder text-dark'>Description</label>
                          <textarea
                            placeholder='Description'
                            value={values.detailScience}
                            className={clsx('form-control form-control-lg form-control-solid', {
                              'is-invalid': errors.detailScience,
                            })}
                            onChange={handleChange('detailScience')}
                            rows={5}
                            cols={5}
                            name='detailScience'
                            autoComplete='off'
                          />
                          <div className='text-danger d-block'>{errors.detailScience || ''}</div>
                        </div>
                      </div>
                      <div className='row p-5'>
                        <div className='col-6'>
                          <label className='form-label fs-6 fw-bolder text-dark'>No of Days</label>
                          <input
                            placeholder='Interval'
                            value={values.executionInterval}
                            className={clsx('form-control form-control-lg form-control-solid', {
                              'is-invalid': errors.executionInterval,
                            })}
                            onChange={handleChange('executionInterval')}
                            type='number'
                            name='executionInterval'
                            autoComplete='off'
                          />
                          <div className='text-danger d-block'>
                            {errors.executionInterval || ''}
                          </div>
                        </div>
                        <div className='col-6'>
                          <label className='form-label fs-6 fw-bolder text-dark'>Time</label>
                          <input
                            placeholder='Time'
                            value={values.executionTime}
                            className={clsx('form-control form-control-lg form-control-solid', {
                              'is-invalid': errors.executionTime,
                            })}
                            onChange={handleChange('executionTime')}
                            type='text'
                            name='executionTime'
                            autoComplete='off'
                          />
                          <div className='text-danger d-block'>{errors.executionTime || ''}</div>
                        </div>
                      </div>
                      <div className='row p-5'>
                        <div className='col-6'>
                          <label className='form-label fs-6 fw-bolder text-dark'>Tags</label>
                          <CreatableSelect
                            isClearable
                            isMulti
                            onChange={(e) => {
                              setFieldValue('tags', e)
                            }}
                            value={values.tags}
                          />
                          <div className='text-danger d-block'>{errors.tags || ''}</div>
                        </div>
                      </div>
                      <div className='row p-5'>
                        <div className='col-6'>
                          <label className='form-label fs-6 fw-bolder text-dark'>
                            {values.imageUrl ? 'Replace' : 'Select'} Image
                          </label>
                          <input
                            className='form-control'
                            type='file'
                            id='formFile'
                            name='file_upload'
                            onChange={onFileChange}
                          />
                        </div>
                        {values.imageUrl && (
                          <div className='col-6'>
                            <img src={getImageUrl(values.imageUrl)} alt='img' height={100} />
                          </div>
                        )}
                      </div>
                      <div className='row p-5'>
                        <div className='col-sm-12'>
                          <FieldArray
                            name='instructions'
                            render={(arrayHelpers) => (
                              <>
                                <label className='form-label fs-6 fw-bolder text-dark'>
                                  Instructions
                                </label>
                                {values.instructions &&
                                  values.instructions.length > 0 &&
                                  values.instructions.map((val, i) => {
                                    return (
                                      <div className='row' key={`instructions-${i}-key`}>
                                        <div className='col-6 pb-5'>
                                          <TextField
                                            className={clsx(
                                              'form-control form-control-lg form-control-solid',
                                              {
                                                'is-invalid': errors.instructions,
                                              }
                                            )}
                                            variant='filled'
                                            value={values.instructions[i]}
                                            onChange={(e) =>
                                              setFieldValue(`instructions[${i}]`, e.target.value)
                                            }
                                            error={!!errors.instructions}
                                          />
                                        </div>
                                        <div className='col-sm-1 my-auto mt-1'>
                                          {values.instructions.length - 1 === i && (
                                            <button
                                              key={`instructions-Add-button`}
                                              type='button'
                                              style={{
                                                borderRadius: '50%',
                                              }}
                                              className='btn btn-primary btn-icon btn-xs ms-3'
                                              onClick={() => arrayHelpers.push('')}
                                            >
                                              <i className='fa fa-plus' aria-hidden='true'></i>
                                            </button>
                                          )}
                                        </div>
                                        <div className='col-sm-1 my-auto mt-1'>
                                          {values.instructions.length !== 1 && (
                                            <button
                                              type='button'
                                              className='btn btn-danger btn-icon btn-xs'
                                              style={{
                                                borderRadius: '50%',
                                              }}
                                              onClick={() => arrayHelpers.remove(i)}
                                            >
                                              <i className='fa fa-minus' aria-hidden='true'></i>
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    )
                                  })}
                              </>
                            )}
                          />

                          <div className='text-danger d-block'>{errors.instructions || ''}</div>
                        </div>
                      </div>
                      <div className='text-end p-5'>
                        <button
                          key={`Admin-Submit-button`}
                          type='button'
                          className='btn btn-primary'
                          onClick={() => handleSubmit()}
                        >
                          {params.id ? 'Update' : 'Create'}
                        </button>
                      </div>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
