/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  // const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/organization'
        icon='/media/icons/duotune/general/Layers.svg'
        title='Organizations'
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/users'
        icon='/media/icons/duotune/communication/com014.svg'
        title='Users'
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='user-wait/list'
        icon='/media/icons/duotune/communication/com014.svg'
        title='Wait List'
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/themes'
        icon='/media/icons/duotune/general/gen011.svg'
        title='Themes'
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/biohack'
        icon='/media/icons/duotune/general/gen032.svg'
        title='Biohacks'
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/assessment'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Assessment'
        fontIcon='bi-app-indicator'
      />
    </>
  )
}
