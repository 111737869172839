import clsx from 'clsx'
import {Formik} from 'formik'
import {get} from 'lodash'
import {useSnackbar} from 'notistack'
import React, {useCallback, useEffect, useState} from 'react'
import {useParams} from 'react-router'
import {useNavigate} from 'react-router-dom'
import Select from 'react-select'
import {PageTitle} from '../../../../_metronic/layout/core'
import '../../../index.scss'
import {EditUser, getOrganizationList, getUserById} from '../redux/UserService'

export const UserEdit = () => {
  const params = useParams()
  const navigate = useNavigate()
  const {enqueueSnackbar} = useSnackbar()
  const [organizationList, setOrganizationList] = useState([])

  const defaultUserDetails = {
    name: '',
    email: '',
    organizationName: '',
    organizationId: '',
  }
  const [userData, setUserData] = useState(defaultUserDetails)

  const getOrganizations = useCallback(async () => {
    try {
      const result: any = await getOrganizationList()
      const data = get(result.data, 'data', [])
      setOrganizationList(
        data.map((org: any) => {
          return {
            label: org.organizationName,
            value: org._id,
          }
        })
      )
    } catch (error) {
      console.log(error)
    }
  }, [])

  const getUserDetails = useCallback(async () => {
    try {
      const result = await getUserById(params.id)
      setUserData(result.data)
    } catch (error) {
      console.log(error)
    }
  }, [params.id])

  useEffect(() => {
    getUserDetails()
    getOrganizations()
  }, [getUserDetails, getOrganizations])

  const onValidate = async (values: any) => {
    let error: any = {}
    if (!values.organizationName) {
      error.organizationName = 'Required'
    }
    if (!values.email) {
      error.email = 'Required'
    }
    if (!values.name) {
      error.name = 'Required'
    }
    return error
  }

  const onSubmit = async (values: any) => {
    if (params.id) {
      const req = {
        _Id: params.id,
        signUpData: {
          organizationName: values.organizationName,
          organizationId: values.organizationId,
          email: values.email,
          name: values.name,
        },
      }

      await EditUser(req)
      enqueueSnackbar('Updated Successfully', {variant: 'success'})
      navigate(`/users`)
    } else {
      enqueueSnackbar('Error while Updating', {variant: 'error'})
    }
  }
  return (
    <>
      <div>
        <PageTitle breadcrumbs={[]}>Edit-User</PageTitle>
        <div className='mt-2'>
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-header justify-content-start align-items-center p-0'>
                  <button type='button' className='btn p-3' onClick={() => navigate(`/users`)}>
                    <img
                      alt=''
                      className='arrow-btn'
                      src='/media/icons/duotune/arrows/arr063.svg'
                    />
                  </button>
                  <p className='fs-4 fw-bolder text-dark m-0'>Back</p>
                </div>
                <Formik
                  enableReinitialize
                  initialValues={userData}
                  onSubmit={(v) => onSubmit(v)}
                  validate={onValidate}
                >
                  {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => (
                    <div>
                      <div className='row p-5'>
                        <div className='col-6'>
                          <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
                          <input
                            placeholder='Email'
                            value={values.email}
                            className={clsx('form-control form-control-lg form-control-solid', {
                              'is-invalid': errors.email,
                            })}
                            onChange={handleChange('email')}
                            type='email'
                            name='email'
                            autoComplete='off'
                          />
                          <div className='text-danger d-block'>{errors.email || ''}</div>
                        </div>
                        <div className='col-6'>
                          <label className='form-label fs-6 fw-bolder text-dark'>User Name</label>
                          <input
                            placeholder='User Name'
                            value={values.name}
                            className={clsx('form-control form-control-lg form-control-solid', {
                              'is-invalid': errors.name,
                            })}
                            onChange={handleChange('name')}
                            type='text'
                            name='name'
                            autoComplete='off'
                          />
                          <div className='text-danger d-block'>{errors.name || ''}</div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-6 ps-9 pt-5'>
                          <label className='form-label fs-6 fw-bolder text-dark'>
                            Organization Name
                          </label>
                          <Select
                            value={organizationList.filter(
                              (option: any) => option.value === values.organizationId
                            )}
                            options={organizationList.filter((x: any) => x.value)}
                            onChange={(val: any) => {
                              setFieldValue('organizationName', val.label)
                              setFieldValue('organizationId', val.value)
                            }}
                            placeholder='Select Organization'
                          />
                          <div className='text-danger d-block'>{errors.organizationName || ''}</div>
                        </div>
                      </div>
                      <div className='text-end p-5'>
                        <button
                          key={`Admin-Submit-button`}
                          type='button'
                          className='btn btn-primary'
                          onClick={() => handleSubmit()}
                        >
                          {params.id ? 'Update' : 'Create'}
                        </button>
                      </div>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
