import axios from 'axios'

const GET_ORGANIZATION_BY_ID = `/organization`
const UPDATE_ORGANIZATION = `/organization/updateOrganization`
const CREATE_ORGANIZATION = `/organization/createOrganization`
const GET_ORGANIZATION_LIST = `/organization/filterOrganization`

export const getOrganizationList = (req: any) => {
  return axios.post(GET_ORGANIZATION_LIST, req)
}

export function CreateOrganization(name: any) {
  return axios.post(CREATE_ORGANIZATION, {
    organizationName: name,
  })
}

export function EditOrganization(req: any) {
  return axios.patch(UPDATE_ORGANIZATION, req)
}

export function getOrganizationById(id: any) {
  return axios.get(`${GET_ORGANIZATION_BY_ID}/${id}`)
}
