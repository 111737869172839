import React from 'react'
import {Spinner} from 'react-bootstrap'

interface Props {
  isLoading: boolean
  message?: string
  backdropStyle?: any
  messageStyle?: any
}

const CustomLoadingAnimation = ({isLoading, message, backdropStyle = {}, messageStyle}: Props) => {
  return isLoading ? (
    <div
      style={{
        zIndex: 999,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
      }}
    >
      <div className='text-center'>
        <Spinner animation='border' role='status' variant='primary' style={{width: 50, height: 50}}>
          <span className='sr-only'>Loading...</span>
        </Spinner>
        <p className='font-weight-bold h6 mt-4' style={messageStyle}>
          {message}
        </p>
      </div>
    </div>
  ) : null
}

export default CustomLoadingAnimation
