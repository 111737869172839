import axios from 'axios'

const headers = {'Content-Type': 'multipart/form-data'}

const GET_FILE = `/assessment-questions/questionsFromSheet`
const GET_THEMES_LIST = `/theme/Theme`
const GET_QUESTIONS_BY_ID = `/assessment-questions`
const GET_THEME_BY_ID = `/theme`

export function getThemes() {
  return axios.post(`${GET_THEMES_LIST}`)
}

export function getFile(req: any) {
  return axios.post('/assessment-questions/questionsFromSheet', req, {headers})
}

export function updateFile(id: any, req: any) {
  return axios.patch(`${GET_FILE}/${id}`, req)
}

export function getQuestions(req: any) {
  return axios.post('/assessment-questions/allAddedQuestions', req)
}

export function getQuestionsById(id: any) {
  return axios.get(`${GET_QUESTIONS_BY_ID}/${id}`)
}

export function getThemeById(id: any) {
  return axios.get(`${GET_THEME_BY_ID}/${id}`)
}

export function getPreSignedUrl(filePath: string) {
  return axios.post('/upload/preSignedUrl', {filePath})
}

export function uploadToS3(url: string, body: any, headers: Headers) {
  return fetch(url, {
    method: 'PUT',
    headers,
    body,
  })
}

export function updateQuestion(body: any) {
  return axios.post('/assessment-questions/opt', body)
}
