import axios from 'axios'


const GET_USER_LIST = `/waitlist/getWaitList`
const UPDATE_USER = `/waitlist/approval`
const GET_USER_BY_ID = `/waitlist`
const GET_ORGANIZATION_LIST = `/organization/filterOrganization`

export const getUserList = (req: any) => {
  return axios.post(GET_USER_LIST, req)
}
export function EditUser(id: any,req: any) {
  return axios.post(`${UPDATE_USER}/${id}`, req)
}

export function getUserById(id: any) {
  return axios.get(`${GET_USER_BY_ID}/${id}`)
}

export const getOrganizationList = () => {
  return axios.post(GET_ORGANIZATION_LIST)
}
