import React, {useCallback, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import SVG from 'react-inlinesvg'
import {useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../../_metronic/layout/core'
import CustomLoadingAnimation from '../../../components/CustomLoadingAnimation'
import '../../../index.scss'
import {deleteUserAPI, getUSerList} from '../redux/UserService'

export const UserList = () => {
  const [loading, setLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0)
  const [userList, setUserList] = useState([])
  const navigate = useNavigate()
  const [userId, setUserId] = useState('')

  let initialFilters = {
    page: 1,
    limit: 10,
    sort: 'des',
    filter: {},
  }
  const [filters, setFilters] = useState(initialFilters)
  const [showModal, setShowModal] = useState(false)
  const [rowData, setRowData] = useState('')

  const onPageChange = async (page: any) => {
    setFilters({
      ...filters,
      page,
    })
  }

  const onSizePerPageChange = (limit: any) => {
    setFilters({
      ...filters,
      page: 1,
      limit,
    })
  }
  const getList = useCallback(async () => {
    try {
      console.log('first')
      setLoading(true)
      const result = await getUSerList(filters) //get data from api
      const count = result.data.count
      setUserList(result.data.data)
      setTotalCount(count)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [filters])

  const onEdit = (id: any) => {
    navigate(`/user/edit/${id}`)
  }

  const deleteUser = async () => {
    try {
      console.log('userId', userId)
      const resp = await deleteUserAPI(userId)
      console.log(resp)
      setShowModal(false)
      getList()
    } catch (error) {
      console.log(error)
    }
  }
  const setDeleteUser = (row: any) => {
    setShowModal(true)
    setUserId(row)
  }

  const actionColumnView = (row: any) => {
    setRowData(row)
    return (
      <>
        <button
          title='Delete'
          className='btn btn-danger btn-sm mx-3'
          onClick={() => setDeleteUser(row)}
        >
          Delete
        </button>
        <button
          title='Edit User'
          className='btn btn-icon btn-light btn-hover-primary btn-sm mx-3'
          onClick={() => onEdit && onEdit(row)}
        >
          <span className='svg-icon svg-icon-md svg-icon-primary' title='Edit Admin'>
            <SVG title='Edit Admin' src={toAbsoluteUrl('/media/icons/Write.svg')} />
          </span>
        </button>
      </>
    )
  }

  const columns = [
    {
      dataField: 'email',
      text: 'User Email',
    },
    {
      dataField: 'name',
      text: 'User Name',
    },
    {
      dataField: 'organizationName',
      text: 'Org Name',
    },
    {
      dataField: 'role',
      text: 'Role',
    },
    {
      dataField: '_id',
      text: 'Actions',
      formatter: actionColumnView,
      headerStyle: {
        maxWidth: '50px',
      },
      style: {
        maxWidth: '50px',
      },
      align: 'right',
    },
  ]

  useEffect(() => {
    getList()
  }, [filters, getList])

  return (
    <>
      <Modal show={showModal}>
        <Modal.Header closeButton={!showModal}>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Are You Sure You Want To Delete User?.</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant='danger' onClick={() => deleteUser()}>
            Confirm
          </Button>
          <Button variant='primary' onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <CustomLoadingAnimation isLoading={loading} />
      <div>
        <PageTitle>Users</PageTitle>
        <div className='container-fluid mt-2'>
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='col-12 p-5'>
                  {!loading && userList.length > 0 && (
                    <BootstrapTable
                      wrapperClasses='table-responsive'
                      classes='table table-head-custom table-vertical-center overflow-hidden'
                      bootstrap4
                      remote
                      columns={columns}
                      data={userList}
                      keyField='_id'
                      striped
                      pagination={paginationFactory({
                        page: filters.page,
                        sizePerPage: filters.limit,
                        totalSize: totalCount,
                        onPageChange: onPageChange,
                        onSizePerPageChange: onSizePerPageChange,
                      })}
                    />
                  )}
                </div>
                {/* If No records */}
                {!loading && userList.length === 0 && (
                  <div className='row'>
                    <div className='col-xl-12'>
                      <div className='card'>
                        <div className='card-body text-center'>No results found</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserList
