import React, {useCallback, useEffect, useState} from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import SVG from 'react-inlinesvg'
import {useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import CustomLoadingAnimation from '../../components/CustomLoadingAnimation'
import {getBiohacks} from './redux/BiohackService'

export const BiohackList = () => {
  const navigate = useNavigate()
  const [totalCount, setTotalCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [biohackList, setBiohackList] = useState([])
  const [showLess, setShowLess] = useState(true)

  let initialFilters = {
    page: 1,
    limit: 10,
    sort: 'des',
    filter: {},
  }
  const [filters, setFilters] = useState(initialFilters)

  const onPageChange = async (page: any) => {
    setFilters({
      ...filters,
      page,
    })
  }

  const onSizePerPageChange = (limit: any) => {
    setFilters({
      ...filters,
      page: 1,
      limit,
    })
  }

  const bioHackList = useCallback(async () => {
    try {
      setLoading(true)
      const result: any = await getBiohacks(filters)
      const count = result.data.count
      setBiohackList(result.data.data)
      setTotalCount(count)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [])

  const onEdit = (id: any) => {
    navigate(`/biohack/edit/${id}`)
  }

  const actionColumnView = (row: any) => {
    return (
      <>
        <div
          title='Edit Admin'
          className='btn btn-icon btn-light btn-hover-primary btn-sm mx-3'
          onClick={() => onEdit && onEdit(row)}
        >
          <span className='svg-icon svg-icon-md svg-icon-primary' title='Edit Admin'>
            <SVG title='Edit Admin' src={toAbsoluteUrl('/media/icons/Write.svg')} />
          </span>
        </div>
      </>
    )
  }

  const textRead = (row: any) => {
    const length = 70
    if (row.length < length) {
      return <p>{row}</p>
    }
    return (
      <div>
        <p>{showLess ? row.slice(0, length) : row}</p>

        {showLess ? '...' :'...'}
      </div>
    )
  }

  const columns = [
    {
      dataField: 'detailScience',
      text: 'Description',
      formatter: textRead,
    },
    {
      dataField: 'executionInterval',
      text: 'Interval',
    },
    {
      dataField: 'executionTime',
      text: 'Time',
    },
    {
      dataField: 'title',
      text: 'Title',
    },
    {
      dataField: 'instructions',
      text: 'Instructions',
    },
    {
      dataField: 'tags',
      text: 'Tags',
    },
    {
      dataField: '_id',
      text: 'Actions',
      formatter: actionColumnView,

      headerStyle: {
        maxWidth: '50px',
      },
      style: {
        maxWidth: '50px',
      },
      align: 'right',
    },
  ]

  useEffect(() => {
    bioHackList()
  }, [])

  return (
    <>
      <CustomLoadingAnimation isLoading={loading} />
      <div>
        <PageTitle>Biohacks</PageTitle>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-header p-3 justify-content-end'>
                  <button className='btn btn-primary' onClick={() => navigate(`/biohack/create`)}>
                    Create
                  </button>
                </div>
                <div className='col-12 p-5'>
                  {!loading && biohackList.length > 0 && (
                    <BootstrapTable
                      wrapperClasses='table-responsive'
                      classes='table table-head-custom table-vertical-center overflow-hidden'
                      bordered
                      striped
                      remote
                      columns={columns}
                      data={biohackList}
                      keyField='_id'
                      pagination={paginationFactory({
                        page: filters.page,
                        sizePerPage: filters.limit,
                        paginationSize: 5,
                        totalSize: totalCount,
                        onPageChange: onPageChange,
                        onSizePerPageChange: onSizePerPageChange,
                      })}
                    />
                  )}
                </div>
                {/* If No records */}
                {!loading && biohackList.length === 0 && (
                  <div className='row'>
                    <div className='col-xl-12'>
                      <div className='card'>
                        <div className='card-body text-center'>No results found</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
